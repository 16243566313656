
// Custom Theming for Angular Material
// For more information: https://material.angular.io/guide/theming
@import '~@angular/material/theming';
// Plus imports for other components in your app.

// Include the common styles for Angular Material. We include this here so that you only
// have to load a single css file for Angular Material in your app.
// Be sure that you only ever include this mixin once!
@include mat-core();

// Define the palettes for your theme using the Material Design palettes available in palette.scss
// (imported above). For each palette, you can optionally specify a default, lighter, and darker
// hue. Available color palettes: https://material.io/design/color/
$store-primary: mat-palette($mat-indigo);
$store-accent: mat-palette($mat-pink, A200, A100, A400);

// The warn palette is optional (defaults to red).
$store-warn: mat-palette($mat-red);

// Create the theme object. A theme consists of configurations for individual
// theming systems such as "color" or "typography".
$store-theme: mat-light-theme((
  color: (
    primary: $store-primary,
    accent: $store-accent,
    warn: $store-warn,
  )
));

// Include theme styles for core and each component used in your app.
// Alternatively, you can import and @include the theme mixins for each component
// that you are using.
@include angular-material-theme($store-theme);

/* You can add global styles to this file, and also import other style files */
* {
  font-family: -apple-system, "Helvetica Neue", Roboto, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  box-sizing: border-box;
}

/* You can add global styles to this file, and also import other style files */
html, body {
  height: 100%;
  font-size: 16px;
}

body {
  padding-top: 3.5rem;
  margin: 0 auto;
  font-family: -apple-system, "Helvetica Neue", Roboto, sans-serif;
  font-size: 0.875rem;
  background-color: transparent;
}

body {
  .mat-dialog-container {
    padding: 0;
    overflow: hidden;
    border-radius: 0.5rem;
  }

  .cdk-overlay-dark-backdrop {
    background: rgb(255 255 255 / 82%);
  }

  .product-info-dialog {
    width: 56rem;
    height: 36rem;
  }
}

@media screen
and (min-device-width: 320px)
and (max-device-width: 480px) {
  body {
    .product-info-dialog {
      max-width: 100% !important;
      position: absolute !important;
      left: 0;
      top: 0;
      width: 100%;
      height: 100%;
    }
  }
}
